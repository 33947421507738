/* eslint-disable max-lines */
import React, { useState } from 'react';
import {
  Autocomplete,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Popper,
  TextField,
  Tooltip,
  Typography,
  autocompleteClasses,
  createFilterOptions
} from '@mui/material';
import {
  MSQ_IMM,
  MSQ_DET,
  MSQ_UT,
} from '@lba-dev/package.local-globals/questionTypeMSteps';
import { List } from 'immutable';
import CustomTextField from '../../../CustomInputs/CustomTextField';
import { Info } from '@mui/icons-material';
import { Box } from '@mui/system';
import classNames from 'classnames';
import { sortedValues } from '../../../../utils/sortedHelpers';
import { genHTMLContent, isHTML } from '../../helpers';

/**
 * @param {{value: ResponseMetier, responses: ResponseMetier[],
 * countImportant, number, suggestion: string, question: QuestionMetier}} params
 */
const getData = ({
  value,
  responses,
  countImportant,
  suggestion = '',
  question = {}
}) => ({
  value: value.response,
  typereponse: value.responseType,
  tag: question.questionType === MSQ_UT ? false : value.tag,
  refTag: value.refTag,
  hasImportant: responses.some(e => e.important),
  countImportant,
  suggestion
});

const areEqual = (array1, array2) => {
  if (array1.length === array2.length) {
    return array1.every(element => !!array2.includes(element));
  }
  return false;
};

/**
 * Sort Responses based on language-sensitive
 * @param {ResponseMetier[]} responsesMetier
 * @param {QuestionMetier} question
 * @returns {ResponseMetier[]}
 */
export const reOrderResp = (responsesMetier, question) => {
  const texts = [
    'Autres',
    'Informations complémentaires',
    'Je ne sais pas',
    'Je ne connais pas la marque'
  ];

  let responses = []
  const rest = []

  responsesMetier.forEach((element) => {
    if (!texts.includes(element.response)) {
      responses.push(element)
    } else {
      rest.push(element)
    }
  })

  responses = question.sorted
    ? responses.sort((a, b) => sortedValues(a?.response, b?.response))
    : responses;
  return [...responses, ...rest];
};

const setSubAnswer = ({
  newAnswers,
  elem,
  subQuestions,
  answer,
  setAnswers,
  responses,
  countImportant,
  numOcc,
  questionName
}) => {
  const subAnswers = newAnswers
    .set(questionName, answer)
    .filter((v, k) => subQuestions.some(e => {
      const subQuestionName = e.maxDuplicate > 0 && numOcc
        ? `${numOcc} ${e.question}`.trim()
        : e.question;
      return k === subQuestionName
    }))
    .toJS();

  const myAltAnswers = Object.entries(subAnswers)
    .map(([k, v]) => {
      const refTag = subQuestions
        ?.find(e => e.question === k)
        ?.responses
        ?.find(e => e.response === v)
        ?.refTag;
      return refTag;
    })
    .filter(Boolean);

  const alternativeAnswer = elem.responses.find(e =>
    areEqual((e.altAnswers || []).map(a => a.refTag), myAltAnswers)
  );

  if (alternativeAnswer && myAltAnswers.length) {
    setAnswers(
      [questionName],
      alternativeAnswer.response,
      [getData({ value: alternativeAnswer, responses, countImportant })]
    );
  }
};

export const GenQuestion = ({ index, responses, collection, question }) => (
  <Grid item>
    <Typography
      key={index}
      style={{
        whiteSpace: 'nowrap',
        fontSize: 15,
        lineHeight: (responses || []).length ? '0.5' : '2',
        fontWeight:
          question.questionType === MSQ_DET &&
            (responses || []).length &&
            responses.some((s) => s.tag) &&
            collection === 'metiersDevis' ? 'bold' : 'normal',
      }}
      children={`${isNaN(index) ? index : index + 1}. ${question.question}`}
    />
  </Grid>
);

export const AnswerBoolean = ({
  question,
  newAnswers,
  classes,
  restart,
  setAnswers,
  numOcc
}) => {

  const handleChange = (value) => {
    const questionName = question.maxDuplicate > 0 && numOcc
      ? `${numOcc} ${question.question}`.trim()
      : question.question;

    setAnswers([questionName], value);
  };

  return question.questionType === MSQ_IMM
    ? [
      { label: 'Oui', value: '1' },
      { label: 'Non', value: '-1' },
    ].map((l, i) => (
      <Grid key={i} item>
        <Chip
          className={classes.chip}
          color={
            newAnswers.has(question.question) &&
              (+newAnswers.get(question.question) || 0).toString() === l.value
              ? 'primary'
              : 'default'
          }
          label={l.label}
          onClick={() => !restart && handleChange(+l.value)}
        />
      </Grid>
    ))
    : '';
}

export const FreeTextField = ({
  answers,
  question,
  setAnswers,
  restart,
  numOcc
}) => {
  const questionName = question.maxDuplicate > 0 && numOcc
    ? `${numOcc} ${question.question}`.trim()
    : question.question;

  const handleDataChange = (path, value) => {
    setAnswers([questionName], value);
  }
  return (
    <Grid item>
      <MouseHoverPopover
        content={question.infobulle}
        tooltip
      >
        <CustomTextField
          InputProps={{ style: { padding: 0, height: 32 } }}
          path={''}
          disabled={restart}
          setData={handleDataChange}
          value={answers.getIn([questionName])}
        />
      </MouseHoverPopover>
    </Grid>
  )
}

export const AnswerDuo = ({
  question,
  newAnswers,
  classes,
  restart,
  setAnswers,
  countImportant,
  allResponses,
  response,
  numOcc
}) => {
  const questionName = question.maxDuplicate > 0 && numOcc
    ? `${numOcc} ${question.question}`.trim()
    : question.question;
  const res = newAnswers.get(questionName);
  const isMulti = List.isList(res);

  return <Grid item>
    <MouseHoverPopover
      content={response.infobulle}
      tooltip
    >
      <Chip
        className={classes.chip}
        color={(
          question.multipleChoice &&
          newAnswers.has(questionName) &&
          newAnswers.get(questionName).includes(response.response)
        ) || newAnswers.get(questionName) === response.response
          ? 'primary'
          : 'default'
        }
        label={response.response}
        onClick={() => {
          if (!restart) {
            // unpick old subquestions answers
            const oldSubQuestions = question.responses
              .filter(e =>
                e.response !== response.response &&
                e.subQuestions &&
                e.subQuestions.length
              )
              .map(e => e.subQuestions)
              .flat();
            oldSubQuestions.forEach(e => {
              const subQuestionName = e.maxDuplicate > 0 && numOcc
                ? `${numOcc} ${e.question}`.trim()
                : e.question;
              if (newAnswers.get(subQuestionName) && !e.defaultValue) {
                setAnswers([subQuestionName], false, []);
              }
            });

            return setAnswers(
              [questionName],
              (isMulti && (newAnswers.has(questionName)
                ? newAnswers
                  .get(questionName)
                  .toggleValue(response.response)
                : new List([response.response]))
              ) || response.response,
              [getData({
                value: response,
                responses: allResponses,
                countImportant,
                question
              })],
            );
          }
        }}
      />
    </MouseHoverPopover>
  </Grid>;
};

export const GenPopperImage = ({ response }) => {
  const [showImage, setShowImage] = useState(false);
  const handlePopoverOpen = () => {
    setShowImage(true)
  }
  const handleClose = () => {
    setShowImage(false)
  }
  return response?.image
    ? <Grid item sx={{ position: 'relative' }}>
      <IconButton
        size="small"
        children={<Info />}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handleClose}
      />
      {showImage ?
        <Box component={Paper} sx={{ p: 2 }}
          style={{
            position: 'fixed',
            top: 20,
            right: -215,
          }}
        >
          <img
            alt="metiers"
            src={response.image}
            style={{ width: 200, height: 200, objectFit: 'cover' }}
          />
        </Box> : ''}
    </Grid>
    : '';
};

export const GenSuggest = ({
  newAnswers,
  question,
  responses,
  setAnswers,
  infoComps,
  restart,
  countImportant,
  numOcc,
  isSub = false,
  text = '',
}) => {
  const questionName = (question.maxDuplicate > 0 && numOcc) || isSub
    ? `${numOcc} ${question.question}`.trim()
    : question.question;
  const res = newAnswers.get(questionName);
  const isMulti = List.isList(res);
  const response = isMulti && res
    ? responses?.filter(r => res?.some(e => e === r.response))
    : [responses?.find(r => r.response === res)];
  const data = (v = '') => ({
    'informations complémentaires': {
      key: 'infoComp',
      placeholder: '',
      current: infoComps,
      value: { text: v },
    }
  })[text] || {};
  const sampleData = data();
  const cond = isMulti
    ? Array.isArray(res) && res?.some((e) => e?.toLowerCase().includes(text))
    : typeof res === 'string' && res?.toLowerCase().includes(text);
  if (res && response && cond) {
    return <Grid item>
      {!restart
        ? <CustomTextField
          InputProps={{ style: { padding: 0, height: 32 } }}
          texttransform='none'
          placeholder={sampleData.placeholder}
          path={''}
          value={sampleData.current.getIn([questionName, 'text'])}
          setData={(p, v) => setAnswers(
            [questionName],
            res,
            response.map(r => ({
              typereponse: r.responseType,
              tag: r.tag,
              hasImportant: responses.some(e => e.important),
              countImportant,
              [sampleData.key]: data(v).value,
            }))
          )}
        />
        : <Typography
          sx={{ fontSize: 15 }}
          children={sampleData.current.getIn([questionName, 'text'])}
        />}
    </Grid>;
  }
  return '';
};

const CustomPopper = function (props) {
  return <Popper {...props}
    style={{
      width: 'fit-content',
      minWidth: props.style.width || 'auto',
    }} placement="bottom-start" />;
};

export const GenDropdown = ({
  responses,
  newAnswers,
  elem,
  classes,
  setAnswers,
  restart,
  countImportant,
  subQuestions = [],
  isSub = false,
  metierElement,
  user,
  numOcc
}) => {
  const questionName = (elem.maxDuplicate > 0 && numOcc) || isSub
    ? `${numOcc} ${elem.question}`.trim()
    : elem.question;
  const res = newAnswers.get(questionName);
  const isMulti = res ? List.isList(res) : elem.multipleChoice;
  let elemRes = newAnswers.get(questionName);
  elemRes = List.isList(elemRes) ? elemRes.toJS() : elemRes
  const filter = createFilterOptions();
  const value = (Array.isArray(elemRes) ?
    elemRes : [elemRes]).filter(Boolean)
    .map(e => {
      const resp = responses.find(r => r.response === e);
      return { response: e, title: e, newElement: !resp }
    });

  const genSuggestionData = (value) =>
    getData({
      value: {
        response: value.response,
        responseType: 0,
      },
      responses,
      countImportant,
      question: elem,
      suggestion: {
        ref: elem.ref,
        cat: elem.cat,
        categorie: metierElement.categorie,
        text: value.response,
        date: new Date(),
        desc: metierElement.description,
        login: user.login
      },
    })

  const handleInputChange = (_, value) => {
    let answer;
    let data = [];
    value = isMulti ? value : (value[value.length - 1] || { response: '' });
    if (typeof value === 'string') {
      value = { response: value };
    }
    let resp = !isMulti && responses.find(e => (e.response || '')
      .trim().toLowerCase() ===
      (value?.response || '').trim().toLowerCase());
    if (!isMulti && !resp) {
      data = [genSuggestionData(value)];
      answer = newAnswers
        .get(questionName) !== value.response && value.response;
    } else if (isMulti) {
      data = value.map(r => {
        resp = responses.find(e =>
          (e.response || '').trim().toLowerCase() ===
          (r?.response || '').trim().toLowerCase());
        return !resp ?
          genSuggestionData(r) :
          getData({
            value: r,
            responses,
            countImportant,
            question: elem,
          })
      });
      answer = new List(value.map(e => e.response));
    } else {
      data = [getData({ value, responses, countImportant, question: elem })];
      answer = newAnswers
        .get(questionName) !== value.response && value.response;
    }
    if (subQuestions.length && isSub) {
      setSubAnswer({
        newAnswers,
        elem,
        subQuestions,
        answer,
        setAnswers,
        responses,
        countImportant,
        numOcc,
        questionName,
      });
    }
    if (!isSub) {
      const oldSubQuestions = elem.responses
        .filter(e => e.response !== answer && e.subQuestions?.length)
        .map(e => e.subQuestions)
        .flat();
      oldSubQuestions.forEach(e => {
        if (newAnswers.get(e.question) && !e.defaultValue) {
          setAnswers([e.question], false, data);
        }
      });
    }
    return !restart && setAnswers([questionName], answer, data);
  }
  return <>
    <Autocomplete
      options={responses.map(e => ({ ...e, title: e.response }))}
      freeSolo={!isMulti}
      multiple={true}
      isOptionEqualToValue={(option, value) => value &&
        option.response === value?.response}
      getOptionLabel={option => option.title}
      PopperComponent={CustomPopper}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== '') {
          filtered.push({
            response: params.inputValue,
            newElement: true,
            title: `Ajouter "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      value={value}
      autoComplete
      onChange={handleInputChange}
      classes={{
        root: classNames(classes.acInput, (value || []).length ? null :
          classes[isMulti ? 'multiACExpanded' : 'aCExpanded']),
      }}
      disableClearable
      sx={{
        [`& .${autocompleteClasses.inputRoot}`]: {
          flexWrap: 'nowrap',
          [`& .${autocompleteClasses.inputFocused}`]: {
            padding: 0,
            minWidth: (value || []).length ? 0 : 30
          },
          [`& .${autocompleteClasses.inputFocused}:focus`]: {
            minWidth: 40
          }
        },
      }}
      disabledItemsFocusable={restart}
      readOnly={restart}
      renderOption={(props, option, { selected }) => (
        <MouseHoverPopover
          content={option.infobulle}
          tooltip
        >
          <MenuItem {...props} fullWidth value={option.title}>
            {isMulti ? <Checkbox checked={selected} /> : null}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography>{option.title}</Typography>
              </Grid>
              {option.image ? <GenPopperImage response={option} /> : ''}
            </Grid>
          </MenuItem>
        </MouseHoverPopover>
      )}
      renderTags={(value, getTagProps) =>
        (value || [])[0].response ? value.map((option, index) => {
          const chipProps = getTagProps({ index })
          return <MouseHoverPopover key={index} content={option.infobulle}>
            <Chip
              {...chipProps}
              size="small"
              className={classes[isMulti ? 'chipDropDown' : 'chip']}
              color="primary"
              onDelete={restart ? null : chipProps.onDelete}
              label={option.response || option}
            />
          </MouseHoverPopover>
        }) : null
      }
      renderInput={params => <TextField
        variant="standard"
        {...params}
        rows={1}
        maxRows={1}
        fullWidth
      />}
    />
  </>;
};

/**
 * @param {{ content: string, children?: React.ReactNode}} props
 */
export function MouseHoverPopover(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!props.content) {
    return props.children;
  }

  if (!isHTML(props.content) || props.tooltip) {
    return <Tooltip
      title={
        genHTMLContent(props.content, true, {})
      }
    >
      {
        props.children ?? <Info
          color="info"
          fontSize="small" />
      }
    </Tooltip>
  }

  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {
          props.children ?? <Info
            color="info"
            fontSize="small" />
        }
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          borderRadius: 50,
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {genHTMLContent(props.content, true, { padding: 10 })}
      </Popover>
    </div>
  );
}

export default {
  civility: ['client', 'civility'],
  name: ['client', 'name'],
  firstname: ['client', 'firstname'],
  tel1: ['client', 'tel1'],
  email1: ['client', 'email1'],
  address: ['client', 'address'],
  number: ['client', 'address', 'number'],
  road: ['client', 'address', 'road'],
  zipcode: ['client', 'address', 'zipcode'],
  city: ['client', 'address', 'city'],
  infoDesc: ['infoDesc'],
  desc: ['infoDesc', 'desc'],
  devis: ['infoDesc', 'devis'],
  context: ['infoDesc', 'context'],
  infoTech: ['infoDesc', 'infoTech'],
  infoClient: ['infoDesc', 'infoClient'],
  consignes: ['infoDesc', 'consignes'],
  toBeSelledOnSite: ['infoDesc', 'toBeSelledOnSite'],
  announcedPrice: ['announcedPrice'],
  announcedPriceMax: ['announcedPriceMax'],
  priceArtisan: ['priceArtisan'],
  finalPrice: ['finalPrice'],
  onSitePayment: ['onSitePayment'],
  smsAxialis: ['smsAxialis'],
  noteSms: ['noteSms'],
  partenariat: ['partenariat'],
  paymentMethod: ['paymentMethod'],
  creditCard: ['creditCard'],
  maxHour: ['infoDesc', 'maxHour'],
  flexHour: ['flexHour'],
  flexDate: ['flexDate'],
  flexPrice: ['flexPrice'],
  hidePrice: ['hidePrice'],
  remindFacture: ['remindFacture'],
  remindInter: ['remindInter'],
  noMiseEnRelation: ['noMiseEnRelation'],
  grandCompte: ['billing', 'grandCompte'],
  categorie: ['categorie'],
  metiersSteps: ['metiersSteps'],
  metiersDevis: ['metiersDevis'],
  definHClient: ['definHClient'],
  definDClient: ['definDClient'],
  status: ['status'],
  subStatus: ['subStatus'],
  commissionValide: ['commissionValide'],
  productsList: ['products', 'list'],
  dropbox: ['dropbox'],
  caldeoStatus: ['caldeoStatus'],

  dateInter: ['date', 'intervention'],

  // info SAV
  descSAV: ['infoDescSAV', 'desc'],
  consignesSAV: ['infoDescSAV', 'consignes'],
  SAVRemun: ['infoDescSAV', 'SAVRemun'],
  SAVFact: ['infoDescSAV', 'SAVFact'],
  SAVImpactPrice: ['infoDescSAV', 'SAVImpactPrice'],
  SAVRemunPrice: ['infoDescSAV', 'SAVRemunPrice'],
  SAVFactPrice: ['infoDescSAV', 'SAVFactPrice'],
  creditCardSAV: ['infoDescSAV', 'creditCard'],

  // billing
  refFacture: ['billing', 'ref'],

  // Payment
  iban: ['iban'],
  bic: ['bic'],
  newIban: ['newIban'],
  newBic: ['newBic'],
  verifRIB: ['verifRIB'],
  // demande
  withoutUpdate: ['demande', 'withoutUpdate'],
  externalId: ['demande', 'externalId'],
  disableNotif: ['disableNotif']
};

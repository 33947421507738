import React, { useMemo, useRef, useState } from 'react';
import EditableList from '../../Dialogs/EditableList';
import {
  data as respType, MSR_AUC
} from '@lba-dev/package.local-globals/responseTypeMSteps';
import {
  Badge,
  Box,
  Grid,
  IconButton,
  Paper,
  Popover,
  Typography
} from '@mui/material';
import { genRandomId } from '../../../utils/number';
import CustomSwitch from '../../CustomInputs/CustomSwitch';
import {
  Image,
  Info,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Link,
  LinkOff,
  QuestionAnswer,
  Queue,
  SortByAlpha,
  TurnedIn,
  TurnedInNot
} from '@mui/icons-material';
import store from '../../../store';
import { setDialogContentProp } from '../../../actions/dialog';
import { questionSteps } from './index';
import { useSelector } from 'react-redux';
import { MSQ_UT } from '@lba-dev/package.local-globals/questionTypeMSteps';
import CustomTextField from '../../CustomInputs/CustomTextField';
import { sortedValues } from '../../../utils/sortedHelpers';

const updateData = (p, v) => store.dispatch(setDialogContentProp(p)(v));

/**
 * Contains keys name of dataTypes variable in file path
 * @type {string[]}
 * @file "../../Dialogs/AddSCatDialog/index.js"
 * @var dataTypes
 * @description pass the keys name of dataTypes than you want to show sorted btn
 */
const dataTypesForSortedDataInput = ['metiersDevis', 'metiersSteps']

const CustomHeader = ({ question, dataType, setDataInput }) => {
  const allSelected = (question.responses || []).every(e => e.important);
  return (
    <Grid container
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={8}>
        <Typography
          children={'Réponses détaillées'}
          variant='subtitle2'
        />
      </Grid>
      <Grid item xs={2}>
        <CustomSwitch
          label="Choix multiple"
          checked={question.multipleChoice}
          path={['']}
          setData={() =>
            setDataInput('multipleChoice', !question.multipleChoice)
          }
        />
      </Grid>
      {dataTypesForSortedDataInput.includes(dataType) && <Grid item>
        <IconButton
          size="large"
          color={question.sorted ? 'success' : 'error'}
          children={<SortByAlpha />}
          onClick={() => setDataInput('sorted', !question.sorted)}
        />
      </Grid>}
      <Grid item>
        <IconButton
          size="large"
          color="inherit"
          children={allSelected ? <TurnedIn /> : <TurnedInNot />}
          onClick={() => {
            const responses = question.responses
              .map(e => ({ ...e, important: !allSelected }));
            setDataInput('responses', responses);
          }}
        />
      </Grid>
    </Grid>
  );
};

const AltAnswersButtons = ({ response, index }) => (
  <Badge
    badgeContent={response?.altAnswers?.length ? 1 : 0}
    color="secondary"
    variant="dot"
  >
    <IconButton
      size="small"
      color="default"
      children={<QuestionAnswer />}
      onClick={() => {
        updateData('stepper', questionSteps.ALT_ANSWERS);
        updateData('selectedResponse', index);
      }}
    />
  </Badge>
);

export const IButton = ({ response, setData, type = 'image' }) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const optionsType = {
    image: {
      icon: <Image />,
      color: response.image ?? 'primary',
      placeholder: 'image...'
    },
    infobulle: {
      icon: <Info />,
      color: 'default',
      placeholder: 'Infobulle...'
    }
  };

  return (
    <>
      <IconButton
        size="large"
        color={optionsType[type]?.color}
        children={optionsType[type]?.icon}
        ref={ref}
        onClick={handleOpen}
      />
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box component={Paper} sx={{ p: 2, width: 300 }} >
          <CustomTextField
            texttransform="none"
            value={response[type]}
            autoFocus
            pasteWithStyle
            margin="dense"
            placeholder={optionsType[type]?.placeholder}
            setData={(p, v) => setData(v)}
          />
        </Box>
      </Popover>
    </>
  );
};


const SubQuestionsButtons = ({ response, index }) => (
  <Badge
    badgeContent={response?.subQuestions?.length ? 1 : 0}
    color="secondary"
    variant="dot"
  >
    <IconButton
      size="small"
      color="default"
      children={<Queue />}
      onClick={() => {
        updateData('stepper', questionSteps.SUB_QUESTIONS);
        updateData('selectedResponse', index);
      }}
    />
  </Badge>
);

const SubResponsesRow = ({
  elem, path, setData, dataType
}) => {
  elem.responses = elem.responses || [];
  return <EditableList
    dataType="questions"
    colSpan={2}
    title={'Réponses détaillées'}
    headers={[
      {
        key: '',
        type: 'empty',
        cellProps: { style: { width: '5%' } },
      },
      {
        key: 'response',
        type: 'text',
        cellProps: { style: { width: '35%' } },
        defaultValue: ''
      },
      {
        key: 'tag',
        type: 'iconButton',
        data: { FirstIcon: Link, SecondIcon: LinkOff, color: true },
        hide: dataType === 'metiersSteps',
        cellProps: { style: { width: '5%' } },
        defaultValue: false
      },
      {
        key: 'refTag',
        type: 'typo',
        cellProps: { style: { width: '10%' } },
        hide: dataType === 'metiersSteps',
        defaultValue: genRandomId('T')
      },
      {
        key: 'image',
        type: 'customComponent',
        data: { Component: IButton },
        cellProps: { style: { width: '5%' } },
        hide: false,
      },
      {
        key: 'open',
        type: 'iconButton',
        data: {
          FirstIcon: Queue,
          SecondIcon: Queue,
          color: (r) => r?.subQuestions?.length ? 'primary' : 'inherit'
        },
        hide: dataType === 'questions',
        cellProps: { style: { width: '5%' } },
        defaultValue: false
      },
      {
        key: 'infobulle',
        type: 'customComponent',
        data: { Component: IButton, props: { type: 'infobulle' } },
        cellProps: { style: { width: 1, textAlign: 'center' } },
        hide: false,
      },
    ]}
    data={elem.responses}
    setData={(data) => setData(path, data)}
    withExpansion={true}
    SubRow={(props) => <SubQuestionRow {...props}
      path={[
        ...path,
        props.index, 'subQuestions'
      ]} setData={setData}
    />
    }
  />;
}


const SubQuestionRow = ({
  elem, path, setData, dataType
}) => {
  elem.subQuestions = elem.subQuestions || [];
  const questions = useSelector(({ questions }) => questions);
  return (
    <Grid container justifyContent="center" sx={{ p: 2 }} >
      <Grid item xs={11}>
        <EditableList
          title={`SOUS-QUESTIONS liées à "${elem.response}"`}
          dataType={dataType}
          headers={[
            {
              key: 'open',
              type: 'iconButton',
              data: {
                FirstIcon: KeyboardArrowDown,
                SecondIcon: KeyboardArrowRight,
              },
              cellProps: { style: { width: 15 } },
              defaultValue: false
            },
            {
              key: '_id',
              type: 'selectQuestion',
              placeholder: 'Question... ?',
              cellProps: { style: { width: 550 } },
              data: {
                questions,
                SCatField: { name: `Sous-question: ${elem.refTag}` },
              },
            },
            {
              key: 'defaultValue',
              type: 'select',
              label: 'Sous-réponse par défaut',
              placeholder: 'Sous-réponse par défaut...',
              defaultValue: '',
              cellProps: { style: { width: 200 } },
              getOptions: (question) => question?.responses
                ?.map(r => r.response),
            },
            {
              key: 'ref',
              type: 'typo',
              title: 'Ref',
              placeholder: 'Ref',
              defaultValue: '',
              cellProps: { style: { width: 100 } },
            },
            {
              key: 'infobulle',
              type: 'customComponent',
              data: { Component: IButton, props: { type: 'infobulle' } },
              cellProps: { style: { width: 1, textAlign: 'center' } },
              hide: false,
            },
          ]}
          data={elem.subQuestions}
          setData={(data) => setData(path, data)}
          withExpansion={true}
          SubRow={(props) => {
            const setResponses = (path, data) => setData(path, data);
            return <SubResponsesRow
              {...props}
              path={[...path, props.index, 'responses']}
              setData={setResponses}
            />;
          }}
        />
      </Grid>
    </Grid>
  );
};

/**
 * QuestionTabs Component
 * @param {{ question: QuestionMetier,
 * setDataInput: (path: string, data: any) => void,
 * dataType: string }} props
 * @returns {JSX.Element}
 */
export const QuestionTabs = ({
  setDataInput,
  question,
  dataType
}) => {

  /**
   * @type {QuestionMetier[]}
   */
  const responsesSorted = useMemo(() => {

    const values = question.responses || [];
    if (question.sorted) {
      return values.sort((a, b) => sortedValues(a?.response, b?.response))
    }

    return values;
  }, [question]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <EditableList
          dataType={dataType}
          colSpan={2}
          title={'Réponses détaillées'}
          customHeader={
            question && dataType !== 'questions' &&
            <CustomHeader
              question={question}
              setDataInput={setDataInput}
              dataType={dataType}
            />
          }
          headers={[
            {
              key: '',
              type: 'empty',
              cellProps: { style: { width: '5%' } },
            },
            {
              key: 'response',
              type: 'text',
              cellProps: { style: { width: '35%' } },
              defaultValue: ''
            },
            {
              key: 'responseType',
              type: 'select',
              data: respType,
              cellProps: { style: { width: '35%', maxWidth: '35%' } },
              defaultValue: MSR_AUC
            },
            {
              key: 'tag',
              type: 'iconButton',
              data: { FirstIcon: Link, SecondIcon: LinkOff, color: true },
              hide:
                dataType === 'metiersSteps' || question.questionType === MSQ_UT,
              cellProps: { style: { width: '5%' } },
              defaultValue: false
            },
            {
              key: 'refTag',
              type: 'typo',
              cellProps: { style: { maxWidth: '20%', minWidth: '20%' } },
              hide: dataType === 'metiersSteps',
              defaultValue: genRandomId('T')
            },
            {
              key: 'image',
              type: 'customComponent',
              data: { Component: IButton },
              hide: false,
            },
            {
              key: 'important',
              type: 'iconButton',
              data: {
                FirstIcon: TurnedIn,
                SecondIcon: TurnedInNot,
              },
              cellProps: { style: { width: '5%' } },
              hide: dataType !== 'metiersDevis',
              defaultValue: false
            },
            {
              key: 'open',
              type: 'iconButton',
              data: {
                FirstIcon: Queue,
                SecondIcon: Queue,
                color: (r) => r?.subQuestions?.length ? 'primary' : 'inherit'
              },
              hide: dataType === 'questions',
              cellProps: { style: { width: '5%' } },
              defaultValue: false
            },
            {
              key: 'varName',
              type: 'customComponent',
              data: { Component: AltAnswersButtons },
              hide: dataType !== 'questions',
            },
            {
              key: 'varName',
              type: 'customComponent',
              data: { Component: SubQuestionsButtons },
              hide: dataType !== 'questions',
            },
            {
              key: 'infobulle',
              type: 'customComponent',
              data: { Component: IButton, props: { type: 'infobulle' } },
              cellProps: { style: { width: 1, textAlign: 'center' } },
              hide: false,
            },
          ]}
          data={responsesSorted || []}
          setData={(data) => setDataInput('responses', data)}
          withExpansion={true}
          SubRow={(props) => {
            const path = ['responses', props.index, 'subQuestions'];
            const setData = (path, data) => setDataInput(path, data);
            return <SubQuestionRow {...props} path={path} setData={setData} />;
          }}
        />
      </Grid>
    </Grid>
  );
}

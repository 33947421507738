import {
  USERS_FETCH,
  USER_CONNECT,
  USER_UPDATE,
} from '../constants/ActionTypes';
import api from '../api';
import notifSystem from '../notifSystem';
import store from '../../src/store';
import { setDialog, setDialogContentProp } from './dialog';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import { List } from 'immutable';
import publicIp from 'public-ip';
import { getFingerprint } from '../utils/manageCookies';

const keys = [
  '_id',
  'login',
  'email',
  'alias',
  'service',
  'maxV',
  'ligneService',
  'ligne',
  'multiLignes',
  'telephoneId',
  'ivrId',
  'ivrNumber',
  'permission',
  'authorizeDistantConnection',
  'act',
  'setPass',
  'commission',
  'late',
  'address',
  'dropbox',
  'leave',
  'receiveLeadsAnyway',
  'appel',
  'name',
  'firstname',
  'privateTel',
  'schedule',
  'goals',
  'support',
  'majSupport',
  'userConnexionTime',
  'categories',
  'market',
  'reciveAutoLeads',
  'grandCompte',
  'nuki',
  'job',
  'nukiAccess',
  'isExtern',
  'has2FA',
  'leadPlanning'
];

const updateUserProperty = ({ id, propertyName, value, users, setUsers }) => {
  let usersList = List.isList(users) ? users.toJS() : users;
  const index = usersList.findIndex(user => user._id === id);
  if (index !== -1) {
    usersList = [
      ...usersList.slice(0, index),
      { ...usersList[index], [propertyName]: value },
      ...usersList.slice(index + 1),
    ];
    setUsers(usersList);
  }
};

export function loadUsers(users) {
  return {
    type: USERS_FETCH,
    users
  };
}

export function userConnect(isActiveSession) {
  return {
    type: USER_CONNECT,
    isActiveSession,
  };
}

export function updateUser(user) {
  return {
    type: USER_UPDATE,
    payload: user,
  };
}

export const resetPassword = async (user) => {
  await api.users.custom('resetPassword').post({
    _id: user._id,
    email: user.email,
    login: user.login,
  });
};

export const changeOpenNewTab = (id, value, users, setUsers) => {
  try {
    api
      .users
      .custom(`${id}`)
      .patch({
        enableOpenNewTab: value
      }).then(() => {
        updateUserProperty({
          id,
          propertyName: 'enableOpenNewTab',
          value,
          users,
          setUsers
        });
        return notifSystem.success(
          'Opération réussie',
          `L'option de changement d'onglet en cas d'appel reconnu a bien été 
        ${value ? 'activée'
    : 'désactivée'} pour cet utilisateur`
        )
      })
  } catch (error) {
    notifSystem.error('Erreur', 'Une erreur est survenu');
    throw error;
  }

}

export const changeUnlimitedSessions = (id, value, users, setUsers) => {
  try {
    api
      .users
      .custom(`${id}`)
      .patch({
        unlimitedSessions: value
      })
      .then(() => {
        updateUserProperty({
          id,
          propertyName: 'unlimitedSessions',
          value,
          users,
          setUsers
        });
        return notifSystem.success(
          'Opération réussie',
          `L'option de changement d'onglet en cas d'appel reconnu a bien été 
        ${value ? 'activée'
    : 'désactivée'} pour cet utilisateur`
        )
      })
  } catch (error) {
    notifSystem.error('Erreur', 'Une erreur est survenu');
    throw error;
  }
};

export const deleteUser = async (usersList, find, id, setUsers) => {
  const res = await api.users.delete(
    find._id,
    { accountUserId: find?.nuki?.accountUserId }
  );
  const del = res.body().data();
  if (del.ok && del.n) {
    let users = usersList;
    users = users.filter(u => u._id !== id);
    setUsers(users);
    return true;
  }
  return false;
};

export const disableUser = (id, act) =>
  api
    .users
    .custom(`${id}/disable`)
    .patch({ act })
    .then(() => notifSystem.success(
      'Opération réussie',
      `L'utilisateur a bien été ${!act ? 'désactivé' : 'activé'}`
    ))
    .catch(e => notifSystem.error(
      'Erreur',
      e.response.data
    ));

const reformat = (keys, object) => Object.fromEntries(
  keys
    .filter(key => key in object)
    .map(key => [key, object[key]])
);

const isError = (toCheck) => {
  const valuesToCheck = [
    { key: 'login', value: toCheck.login },
    { key: 'email', value: toCheck.email },
    { key: 'ligne', value: toCheck.ligne },
    { key: 'Ligne Service', value: toCheck.ligneService },
    { key: 'Ligne Id', value: toCheck.telephoneId },
    ...(!toCheck.isExtern
      ? [
        { key: 'prénom', value: toCheck.firstname },
        { key: 'nom', value: toCheck.name },
      ]
      : []),
  ];
  if (valuesToCheck.some(e => !e.value)) {
    return valuesToCheck.find(e => !e.value).key;
  }
  return false;
};

export const authorizeDistantConnection = (v,
  authorizeDistantUntilWhenLogin) => {
  const isEnable =
    dayjs(new Date()).isBefore(authorizeDistantUntilWhenLogin.from) ||
    dayjs(new Date()).isBetween(authorizeDistantUntilWhenLogin.from,
      authorizeDistantUntilWhenLogin.to, null, '[]');
  api
    .users
    .custom(`${v._id}/updateDistanceLogin`)
    .patch({
      authorizeDistantUntilWhenLogin
    })
    .then(() => notifSystem.success(
      'Opération réussie',
      `La connexion à distance a bien été 
        ${isEnable ? 'activée'
    : 'désactivée'}`
    ))
    .catch(e => notifSystem.error(
      'Erreur',
      e.response.data
    ));
};

export const sendUsers = async (changedIndexes, dataArray) => {
  let formattedUsers = [];
  dataArray.forEach(elem => {
    formattedUsers.push(reformat(keys, elem));
  });
  if (changedIndexes.length > 0) {
    try {
      const toSend = [];
      let toCheck;
      for (let b = 0; b < changedIndexes.length; b++) {
        toCheck = formattedUsers[changedIndexes[b]];
        if (dataArray[changedIndexes[b]].updated) {
          toSend.push(toCheck);
          if (isError(toCheck) !== false) {
            const key = isError(toCheck);
            throw new Error(`Un ou plusieurs champs semblent invalides (${
              key}) - ${toCheck.login}`);
          }
        }
      }
      let newUsers = [];
      const res = await api.users.post(toSend);
      if (res.body().length > 0) {
        newUsers = res.body().map((e) => e.data());
      }
      newUsers = newUsers.map((u) => ({
        ...u,
        check: true,
      }));
      notifSystem.success('Succès', 'Vos informations ont bien été ajoutées');
      return Promise.resolve(newUsers);
    } catch (e) {
      notifSystem.error('Erreur', e.response ? e.response.data : e.message);
      return Promise.reject(e);
    }
  } else {
    notifSystem
      .error('Erreur', 'Un ou plusieurs champs semblent invalides');
  }
};

export const activeNuki = (userId, hasNuki) => {
  api.users
    .custom(`${userId}/updateNuki`)
    .patch({ hasNuki })
    .then(() => notifSystem.success(
      'Opération réussie',
      `L'option a bien été ${!hasNuki ? 'désactivée' : 'activée'}`
    ))
    .catch(e => notifSystem.error('Erreur', e.response.data));
};

export const active2FA = (userId, has2FA) => {
  api.users
    .custom(`${userId}/update2FA`)
    .patch({ has2FA })
    .then(() => notifSystem.success(
      'Opération réussie',
      `L'option a bien été ${has2FA ? 'désactivée' : 'activée'}`
    ))
    .catch(e => notifSystem.error('Erreur', e.response.data));
};

export const addNewUser = ({
  getSchedule,
  state,
  setUsers,
  cb
}) => {
  store.dispatch(
    setDialog({
      name: 'AddNewUser',
      open: true,
      dialogProps: {
        maxWidth: 'md',
      },
      contentProps: {
        getSchedule,
        newUser: {
          login: '',
          email: '',
          alias: '',
          service: 1,
          maxV: 0,
          ligne: '',
          multiLignes: [],
          ligneService: '',
          telephoneId: '',
          act: true,
          permission: 1,
          late: true,
          leave: true,
          schedule: getSchedule(1),
          supportIds: [],
          firstname: '',
          name: '',
          privateTel: '',
          nuki: {
            allowedFromTime: 420,
            allowedUntilTime: 1260,
            allowedWeekDays: [64, 32, 16, 8, 4]
          },
          nukiAccess: false,
          updated: true,
          has2FA: true
        },
      },
      actions: [
        {
          children: 'Ajouter',
          color: 'primary',
          onClick: (data, close) => {
            if (new RegExp(/\./).test(data?.newUser?.login)) {
              notifSystem.error(
                'Erreur',
                'Le login ne doit pas contenir de point'
              );
              return false;
            }
            if (state.find((e) => e.login === data?.newUser?.login)) {
              notifSystem.error(
                'Veuillez changer le login',
                'Un utilisateur avec ce login existe déjà'
              );
              return false;
            }
            data.setLoading(true);
            sendUsers([0], [data.newUser, ...state])
              .then(newUsers =>
                newUsers && setUsers(JSON.parse(JSON.stringify(newUsers)))
              )
              .then(() => {
                cb({ data: [data.newUser, ...state] });
                close();
              })
              .catch((e) => notifSystem.error('Erreur', e.message))
              .finally(() => data.setLoading(false));

          },
        },
      ],
    })
  );
};

const verifySecret = async (authCode, userId, isRemotely) => {
  const res = await api
    .all('verifySecret')
    .post({ authCode, userId, isRemotely });
  return res.body().data();
};

export const twoFactorAuthDialogDialog = (userInfos, cb) => {
  const { _id: userId, isRemotely, hasSecret } = userInfos;
  const step = hasSecret ? 1 : 0;
  store.dispatch(
    setDialog({
      name: 'TwoFactorAuthDialog',
      open: true,
      hideClose: true,
      dialogProps: {
        middleAll: true,
        title: 'Authentification 2FA',
        maxWidth: 'xs',
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
      },
      contentProps: {
        step,
        userId,
        authCode: '',
        error: false
      },
      actions: [
        {
          children: () => 'Valider',
          color: 'primary',
          hideButton: ({ step }) => !step,
          onClick: async ({ authCode }, close) => {
            const {
              verified, token
            } = await verifySecret(authCode, userId, isRemotely);
            if (verified) {
              cb(token);
              close();
            }
            return store.dispatch(setDialogContentProp('error')(true));
          }
        },
        {
          children: 'J\'ai scanné le code',
          color: 'primary',
          hideButton: ({ step }) => step,
          onClick: () => store.dispatch(setDialogContentProp('step')(1))
        }
      ]
    })
  );
};


export const userLogin = async (login, password) => {
  try {
    let ip = '', fingerprint = '';
    if (['stage', 'production'].includes(process.env.NODE_ENV)) {
      ip = await publicIp.v4();
      fingerprint = await getFingerprint();
    }

    const res = await api.login.post({
      ip,
      login,
      password,
      fingerprint
    });

    if ( res.statusCode() === 200){
      return { finishLogin: true, data: res.body().data() }
    }
  }

  catch (error) {
    notifSystem.error(error.message,
      (error.response && error.response.data) || error.name );
  }
  return { finishLogin: false }
};

export const passwordReset = (login) => {
  try {
    api.custom('resetPassword').post({
      login,
    });
    notifSystem.success(
      'Message',
      'Nous vous enverrons un mail de ' +
      'réinitialisation si cet utilisateur existe.'
    );
    return true;
  } catch (error) {
    notifSystem.error(error.name, error.message );
  }
}


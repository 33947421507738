/* eslint-disable max-lines */
import api from '../api';
import { Map } from 'immutable';
import { setDialog } from './dialog';
import store from '../store';
import notifSystem from '../notifSystem';
import { updateDevis } from './devis';
import { openPreview } from './general';
import { postComment } from './inter';
import { C_AIDE } from '@lba-dev/package.local-globals/commentTypes';
import { checkCaldeo } from './verifications';
import { throwIt } from '../utils/function';
import { C_PAC } from '@lba-dev/package.local-globals/comboType';
import { descHabitatData } from
  '@lba-dev/package.local-globals/atlanticQuestion';

export const getCaldeoData = (devisId) =>
  api.devis.getAll({
    query: JSON.stringify({ id: devisId }),
    field: JSON.stringify({
      _id: 1,
      id: 1,
      houseType: 1,
      surface: 1,
      ceeAmount: 1,
      anahAmount: 1,
      contribution: 1,
      signature: 1,
      client: 1,
      combination: 1,
      date: 1,
      domofinance: 1,
      dependent: 1,
      mprEmail: 1,
      mailList: 1,
      login: 1,
      ownerProfile: 1,
      ageHouse: 1,
      houseTotalRevenues: 1,
      pompeAChaleurSimulation: 1,
      chauffeEauThermSimulation: 1,
    })
  }).then(e => e.body()[0].data());

export const sendCaldeo = ({
  interId, close, setLoading, cb, navigate
}, devis) => {
  if (!devis.combination) {
    return notifSystem.error(
      'Combo non trouvé',
      'Veuillez sélectionner un combo'
    );
  }
  const dates = devis.date || {};
  api.devis.custom(`${devis.id}/sendCaldeo`)
    .post({
      interId,
      ...(dates.caldeoDevis ? {
        cDevisDate: dates.caldeoDevis,
      } : {}),
      ...(dates.caldeoFacture ? {
        cFactureDate: dates.caldeoFacture,
      } : {}),
    })
    .then(() => {
      setLoading(false);
      close();
      cb();
      notifSystem.success('Operation réussi',
        'Dossier caldeo a bien été envoyé');
    })
    .then(() => {
      const route = interId ? `/intervention/${interId}` : `/devis/${devis.id}`;
      navigate(route);
    })
    .catch((e) => {
      setLoading(false);
      notifSystem.error(
        'Erreur system',
        ((e.response || {}).data || {}).message || 'Dossier caldeo non envoyé');
    });
};

const setCaldeoData = (setData, devis, type) => Promise.all([
  setData('ceeAmount', devis.get('ceeAmount')),
  setData('anahAmount', devis.get('anahAmount')),
  setData('domofinance', devis.get('domofinance')),
  setData('combination', devis.get('combination')),
  ...(type === 'devis' ? [
    setData('houseType', devis.get('houseType')),
    setData('surface', devis.get('surface')),
    setData('dependent', devis.get('dependent')),
    setData('ownerProfile', devis.get('ownerProfile')),
    setData('ownerZipCode', devis.get('ownerZipCode')),
    setData('ownerCity', devis.get('ownerCity')),
    setData('ageHouse', devis.get('ageHouse')),
    setData('houseTotalRevenues', devis.get('houseTotalRevenues')),
    setData('pompeAChaleurSimulation', devis.get('pompeAChaleurSimulation')),
    setData('chauffeEauThermSimulation',
      devis.get('chauffeEauThermSimulation')),
    setData(['login', 'fillCaldeo'], devis.getIn(['login', 'fillCaldeo'])),
  ] : [])
]);

const updateDevisCaldeo = (data) =>
  updateDevis(data.get('_id'), {
    combination: data.get('combination'),
    houseType: data.get('houseType'),
    surface: data.get('surface'),
    ceeAmount: data.get('ceeAmount'),
    anahAmount: data.get('anahAmount'),
    domofinance: data.get('domofinance'),
    dependent: data.get('dependent'),
    ownerProfile: data.get('ownerProfile'),
    ownerZipCode: data.get('ownerZipCode'),
    ownerCity: data.get('ownerCity'),
    ageHouse: data.get('ageHouse'),
    houseTotalRevenues: data.get('houseTotalRevenues'),
    pompeAChaleurSimulation: data.get('pompeAChaleurSimulation'),
    chauffeEauThermSimulation: data.get('chauffeEauThermSimulation'),
    login: {
      fillCaldeo: data.getIn(['login', 'fillCaldeo']),
    },
  }).then(res => res.body().data());

const saveCaldeoData = async ({
  type, data, save, setData = e => e,
  saveButton, navigate, fromList,
  setLoading, close, cb = f => f
}, saveOnly) => {
  setLoading(true);
  if (type === 'interventions') {
    await setCaldeoData(setData, data);
    const inter = await save(false);
    if (inter && inter.id && data.get('_id')) {
      updateDevisCaldeo(data).then(() => {
        if (saveOnly) {
          cb();
          close();
          return navigate && navigate(`/intervention/${inter.id}`);
        }
        sendCaldeo({
          interId: inter.id, close, setLoading, cb, navigate
        }, data.toJS());
      }).finally(() => setLoading(false));
    }
  } else {
    await setCaldeoData(setData, data, type);
    if (saveButton) {
      setLoading(false);
      cb();
      return close();
    }
    if (fromList) {
      updateDevisCaldeo(data)
        .then((devis) => {
          cb(devis);
          close();
        }).finally(() => setLoading(false));
    } else {
      save(false).then((devis) => {
        if (!devis || !devis.id) {
          setLoading(false);
          return notifSystem.error('Erreur system',
            'Il y a eu une erreur, Veuillez réessayer'
          );
        }
        if (saveOnly) {
          cb();
          setLoading(false);
          close();
          return navigate(`/devis/${devis.id}`);
        }
        sendCaldeo({ close, setLoading, cb, navigate }, {
          ...devis,
          date: {
            ...devis.date,
            caldeoFacture: data.getIn(['date', 'caldeoFacture']),
            caldeoDevis: data.getIn(['date', 'caldeoDevis']),
          }
        });
      }).catch(() => setLoading(false));
    }
  }
};

export const checkValues = (merge, setErrors) => {
  const paths = {
    houseType: ['houseType'],
    surface: ['surface'],
    ageHouse: ['ageHouse'],
    ownerProfile: ['ownerProfile'],
    houseTotalRevenues: ['houseTotalRevenues'],
  };

  const customCheckPaths = {
    ceeAmount: ['ceeAmount'],
  };
  const dialogErrors = [
    ...(Object.keys(paths).map((e) => ({
      path: paths[e], error: !merge.getIn(paths[e])
    }))),
    {
      path: customCheckPaths.ceeAmount,
      error: merge.getIn(['combination', 'comboType']) === C_PAC &&
        (!merge.hasIn(customCheckPaths.ceeAmount) ||
          [undefined, null].includes(merge.getIn(customCheckPaths.ceeAmount)))
    }
  ];
  const errors = dialogErrors.filter(e => e.error);
  if (errors.length) {
    setErrors(errors);
    return false;
  }
  return true;
};


export const openCaldeo = (merge, {
  type = 'devis', cb, userId, ...props
}) => {
  const initData = new Map({
    id: merge.get('id'),
    products: merge.get('products'),
    combination: merge.get('combination'),
    finalPrice: merge.get('finalPrice'),
    client: merge.get('client'),
    date: merge.get('date'),
    billing: merge.get('billing'),
    onSitePayment: merge.get('onSitePayment'),
    ceeAmount: merge.get('ceeAmount', 0),
    anahAmount: merge.get('anahAmount', 0),
    domofinance: merge.get('domofinance', new Map()),
    ...(type === 'devis' ? {
      _id: merge.get('_id'),
      login: merge.get('login'),
      dependent: merge.get('dependent'),
      surface: +merge.getIn([
        'metiersDevis', 'answers', descHabitatData.zone1.at(0).label
      ], 0),
      houseType: merge.get('houseType'),
      mprEmail: merge.get('mprEmail'),
      mailList: merge.get('mailList'),
      ownerProfile: merge.get('ownerProfile'),
      ownerZipCode: merge.get('ownerZipCode', ''),
      ownerCity: merge.get('ownerCity', ''),
      ageHouse: merge.get('ageHouse'),
      houseTotalRevenues: merge.get('houseTotalRevenues'),
      pompeAChaleurSimulation: merge.get('pompeAChaleurSimulation'),
      chauffeEauThermSimulation: merge.get('chauffeEauThermSimulation'),
    } : {})
  });
  const devisExist = merge.get('id') ||
    merge.getIn(['createdFrom', 'collection']) === 'devis';
  const callback = ({
    data, setErrors, setLoading, close
  }, saveOnly) => {
    const newData = (data || initData).setIn(['login', 'fillCaldeo'], userId)
      .setIn(['date', 'fillCaldeo'], new Date());
    if (checkValues(newData, setErrors)) {
      saveCaldeoData({
        type, data: newData, ...props,
        setLoading, close, cb
      }, saveOnly);
    }
  };
  store.dispatch(setDialog({
    name: 'CaldeoDialog',
    open: true,
    hideClose: true,
    contentProps: {
      merge: initData,
      devisId: merge.getIn(['createdFrom', 'id']),
      type,
      ...props
    },
    actions: [
      devisExist && {
        children: 'Génération des documents',
        disabled: () => !merge.has('_id') && props.saveButton,
        onClick: (data, close) => callback({ ...data, close })
      },
      {
        children: 'fermer',
        color: 'secondary',
        onClick: ({ data = new Map() }, close) => {
          if (type === 'devis' && data.getIn(['mprEmail', 'email'])) {
            props.setData(['mprEmail'], data.get('mprEmail'));
          }
          close();
        }
      },
      {
        children: 'Enregistrer',
        color: 'primary',
        onClick: (data, close) => callback({ ...data, close }, true)
      }
    ].filter(Boolean),
  }));
};

export const genMail = (interId) =>
  api.emails.get(interId)
    .then(e => e.body().data())
    .catch(() => notifSystem.error('Erreur system',
      'Il y a eu une erreur, Veuillez réessayer'
    ));

export const handlePreviewCaldeo = (merge) =>
  openPreview({
    method: 'devisFinancement',
    info: {
      subType: 'caldeo',
      type: 'devis'
    },
    data: {
      devis: {
        ...merge.toJS(),
      },
      ...(merge.hasIn(['date', 'caldeoDevis']) ? {
        date: merge.getIn(['date', 'caldeoDevis'])
      } : {})
    }
  }, `Devis caldeo n°${merge.get('id', '')}`, false);


export const priseEnChargeCaldeo = ({ selected, user }, status) =>
  api.interventions.patch(selected._id, {
    login: { caldeoPrisEnCharge: user._id },
    date: { caldeoPrisEnCharge: new Date() },
    caldeoStatus: status
  }).then(() =>
    notifSystem.success('Opération réussie', 'Le status a bien été changé')
  ).catch(e => notifSystem.error(e.name, e.message));

export const changeType = ({ selected }, { comment }, update) =>
  Promise.all([
    postComment(selected._id, comment, { type: C_AIDE }),
    api.interventions.patch(selected._id, update),
  ])
    .then(() =>
      notifSystem.success('Opération réussie', 'Le type a bien été changé')
    )
    .catch((e) => notifSystem.error(e.name, e.message));

export function changeStatusMiddleware(props, {
  title = 'Changer de status',
  withComment = true,
  buttons = [],
  field = '',
  update = {},
  cb,
  ...contentProps
}) {
  contentProps = {
    buttons,
    ...contentProps
  };
  if (!withComment) {
    contentProps.callback = (status) => cb(props, status);
  } else {
    const comments = props?.selected?.comments || [];
    contentProps.comments = comments.filter(e => e.type === C_AIDE).reverse()
      .slice(0, 2);
    contentProps.withColor = false;
  }
  store.dispatch(
    setDialog({
      name: withComment ? 'DialogStatusWithComment' : 'DialogCallBackButton',
      open: true,
      dialogProps: { title, dividers: true },
      contentProps,
      actions: withComment ? [
        {
          children: 'Valider',
          color: 'primary',
          onClick: (validate = {}, close) => {
            if ((validate.comment || '').trim() &&
              ((Array.isArray(validate.status) && validate.status.length) ||
                validate.status)) {
              if (buttons.filter(f => f.field).length) {
                field = buttons.reduce((a, b) => {
                  if ((validate.status || []).includes(b._id)) {
                    a[b.field] = b._id;
                  }
                  return a;
                }, {});
                cb(props, validate, {
                  ...field,
                  ...update
                });
              } else {
                cb(props, validate, {
                  [field]: validate.status,
                  ...update,
                });
              }
              return close();
            }
            let error = '';
            if (!validate.status) {
              error = 'Veuillez choisir un status';
            } else {
              error = 'Veuillez ajouter un commentaire';
            }
            return notifSystem.error('Erreur', error);
          },
        },
      ] : [],
    })
  );
}

export function dialogDeleteCaldeo(props) {
  store.dispatch(
    setDialog({
      name: 'ConfirmDialog',
      open: true,
      dialogProps: {
        title: 'Etes-vous sûr de vouloir supprimer ce dossier ?',
        dividers: true
      },
      contentProps: {
        defaultText: {
          hasTextField: true
        }
      },
      hideClose: true,
      actions: [
        {
          children: 'Annuler',
          color: 'secondary',
          onClick: (props, close) => close()
        },
        {
          children: 'Oui',
          color: 'primary',
          onClick: (validate, close) => {
            if (validate.motifCancel) {
              changeType(props, { comment: validate.motifCancel }, {
                isCaldeo: false,
                combination: {
                  enableCaldeo: false
                }
              });
              return close();
            }
            return notifSystem.error('Erreur',
              'Veuillez ajouter un motif');
          }
        },
      ]
    })
  );
}

export const validateCaldeo = (merge, path, updateData, type) => {
  if (merge.getIn(['combination', 'enableCaldeo']) &&
    ([
      'categorie', 'combination', 'onSitePayment', 'noHelp'
    ].includes(path[0]) ||
      ['civility', 'clientStatus'].includes(path[1])) &&
    merge.get('isCaldeo', false) !== checkCaldeo(merge, type)
  ) {
    const isCaldeo = checkCaldeo(merge, type);
    updateData('isCaldeo', isCaldeo);
    if (isCaldeo && merge.getIn(['combination', 'tva']) &&
      merge.getIn(['products', 'tva']) !== merge
        .getIn(['combination', 'tva'])) {
      updateData(['products', 'tva'], merge.getIn(['combination', 'tva']));
    }
  }
};


export const sendMkukuMail = ({
  devisId,
  subject, message, dest,
  attachments, messageId,
}) => new Promise((res, rej) => {
  if (!subject || !message || !dest) {
    const errorName = (!subject && 'objet') ||
      (!dest && 'destinataire') ||
      (!message && 'message');
    notifSystem.error('Erreur', `Veuillez ajouter un ${errorName}`);
    return rej(throwIt(''));
  }
  return res(api.devis.custom(`${devisId}/sendMkukuMail`)
    .post({
      attachments, subject, message, dest, messageId
    })
    .then(() =>
      notifSystem.success('Opération réussi',
        'Le mail a bien été envoyé'))
    .catch(() => notifSystem.error('Erreur',
      'Le mail n\'a pas pu être envoyé')));
});


import React from 'react';

import MuiTypography from '@mui/material/Typography';
import DOMPurify from 'dompurify';

export const Typography = ({ ...props }) =>
  <MuiTypography
    style={{ fontWeight: 500 }}
    {...props}
  />;

export const genHTMLContent = (
  text = '',
  noLimit,
  style = { paddingLeft: 5 }
) => (
  <Typography
    style={{
      overflow: 'hidden',
      lineHeight: '1.2',
      maxHeight: noLimit ? 'auto' : '1.8em',
      borderLeft: noLimit ? '' : '1px solid #adadad',
      ...style
    }}
    className="editorHtml"
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(text),
    }}
  />
);

/**
 * @param {string} value
 */
export function isHTML(value) {
  const htmlRegex = /<\/?[a-z][\s\S]*>/i;
  return htmlRegex.test(value);
}

import React, { useRef, useState } from 'react';
import {
  Autocomplete,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import MoneyInput from '../../CustomInputs/MoneyInput';
import CustomSelect from '../../CustomInputs/CustomSelect';
import CustomTextField from '../../CustomInputs/CustomTextField';
import { genRandomId } from '../../../utils/number';

export const RenderMenu = ({
  move, index, last, remove
}) => {
  const ref = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const openMenu = () => {
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };
  const removeItem = () => {
    remove(index);
  };
  const goToTop = () => {
    move(index, true);
    closeMenu();
  };
  const goToBottom = () => {
    move(index);
    closeMenu();
  };
  return [
    <IconButton
      key={1}
      ref={ref}
      onClick={openMenu}
      size="large">
      <MoreVert />
    </IconButton>,
    <Menu
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      key={2}
      anchorEl={ref.current}
      open={menuOpen}
      onClose={closeMenu}
    >
      <MenuItem onClick={goToTop} disabled={index === 0}>
        Monter
      </MenuItem>
      <MenuItem disabled={last} onClick={goToBottom}>
        Descendre
      </MenuItem>
      <MenuItem onClick={removeItem}>Supprimer</MenuItem>
    </Menu>,
  ];
};

const items = t => t.map(v =>
  <MenuItem
    key={v._id || v}
    value={v._id || v._id === 0 ? v._id : v}
    disabled={!!v.disabled}
    children={v.name || v}
  />);

export const genComponent = ({
  value, label, type, setData, data, placeholder, hide, ...p
}) => {
  if (!hide) {
    switch (type) {
      case 'selectQuestion': {
        const { questions, SCatField, addQuestion = true } = data;
        const q = questions?.find(e => e._id === value) || p.allValues;
        return <Autocomplete
          options={questions.sort((a, b) => -b.cat.localeCompare(a.cat))}
          groupBy={(option) => option.cat}
          renderGroup={(params) => [
            <Typography
              style={{ fontWeight: 'bold' }}
              key={params.key}
              variant="button"
              children={params.group}
            />,
            params.children.map((e, i) => <Typography key={i} children={e} />)
          ]}
          getOptionLabel={(option) =>
            'question' in option
              ? `${option._id ? `${option.ref} - ` : ''}${option.question}`
              : ''}
          renderInput={(params) =>
            <TextField {...params} placeholder={placeholder} />}
          filterOptions={(options, params) => {
            const { inputValue } = params;
            const formatStr = (str) => str
              .toLowerCase()
              .deaccentize()
              .replaceAll('-', ' ')
              .trim();
            const filtered = options.filter(e => {
              const question = formatStr(e.question);
              const cat = formatStr(e.cat);
              const ref = formatStr(e.ref);
              const input = formatStr(inputValue);
              return [question, cat, ref].some(q => q.includes(input));
            });
            if (inputValue !== '' && addQuestion) {
              const newQ = {
                cat: SCatField.name,
                inputValue,
                question: `Ajouter "${inputValue}"`,
                questionType: 0,
                multipleChoice: false,
                ref: genRandomId('Q'),
                responses: []
              };
              filtered.push(newQ);
            }
            return filtered;
          }}
          freeSolo
          disableClearable
          selectOnFocus
          clearOnBlur
          disableListWrap
          value={q}
          onChange={(_, newValue) => {
            if (newValue._id) {
              setData(newValue, true);
            }
            else if (newValue && newValue.inputValue) {
              newValue.question = newValue.inputValue;
              delete newValue.inputValue;
              setData(newValue, true);
            }
          }}
        />;
      }
      case 'iconButton': {
        const { FirstIcon, SecondIcon, color = false } = data;
        return <IconButton
          size="large"
          color={
            (!color && 'inherit') ||
            (typeof color === 'function' && color(p.allValues)) ||
            (value ? 'primary' : 'secondary')
          }
          children={value ? <FirstIcon /> : <SecondIcon />}
          onClick={() => setData(!value)}
        />;
      }
      case 'customComponent': {
        const { Component, props } = data;
        return <Component
          response={p.allValues}
          index={p.index}
          setData={setData}
          {...props}
        />;
      }
      case 'price':
        return <MoneyInput grid xs={12}
          fullWidth
          label={label}
          defaultValue={value}
          path={['']}
          setData={(p, v) => setData(v)}
          displayText
        />;
      case 'select':
        return <CustomSelect
          value={value}
          label={label}
          path={['']}
          setData={(p, v) => setData(v)}
          children={items(data ?? p.getOptions(p.allValues))}
          displayText
        />;
      case 'empty':
        return '';
      case 'typo':
        return <Typography children={value} align="center" />;
      case 'number':
      case 'text':
      default:
        return (
          <CustomTextField
            type={type}
            fullWidth
            multiline
            value={value}
            label={label}
            placeholder={placeholder}
            texttransform="none"
            setData={(p, v) => setData(v)}
            displayText
          />
        );
    }
  }
};

export const getComponent = (props) => {
  const [editable, setEditable] = useState(false);

  if (editable) {
    return genComponent(props);
  }
  let value = props.value;
  if (props.data) {
    const elem = props.data.find(e => props.value === e._id);
    value = elem ? elem.name : value;
  }
  return props.type === 'empty' ? '' : <Typography
    children={value}
    sx={{ minHeight: 22, borderBottom: '1px dashed grey' }}
    onClick={() => setEditable(true)}
  />;
};

import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

/**
 * Progress bar with label and slow down by waiting timing
 * @param {{
 *  waiting: boolean,
 *  step: number,
 * startAt: number,
 * slowDownStep: number,
 * slowDownAt: number
 * }} props
 */
function LinearProgressWithLabel({
  waiting,
  step = 10,
  startAt = 10,
  slowDownStep = 3,
  slowDownAt = 90
}) {
  const [progress, setProgress] = React.useState(startAt);
  const [maxBeforeComplete] = React.useState(
    98 + Math.floor(Math.random() * 2)
  );
  const timerRef = React.useRef(null);

  React.useEffect(() => {
    if (!waiting) {
      setProgress(100);
      return;
    }

    timerRef.current = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= maxBeforeComplete) {
          clearInterval(timerRef.current);
          return prevProgress;
        }

        let increment = ((prevProgress < slowDownAt) &&
          (prevProgress + step <= slowDownAt)) ? step : slowDownStep;
        return Math.min(prevProgress + increment, maxBeforeComplete);
      });
    }, 800);
    return () => {
      clearInterval(timerRef.current);
    };
  }, [
    waiting,
    slowDownAt,
    maxBeforeComplete,
    step,
    slowDownStep
  ]);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          color={waiting ? 'info' : 'success'}
          variant="determinate"
          value={progress}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {`${Math.round(progress)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.displayName = 'LinearProgressWithLabel';

export default LinearProgressWithLabel;
